@import 'styles/includes.scss';

.ArticleBlock {
    background-color: $colorGray10;
    padding-bottom: 4.8rem;
    padding-top: 4.8rem;

    @include media(l) {
        padding-bottom: 8rem;
        padding-top: 8rem;
    }

    &__Wrapper {
        @extend %container;
    }
}
