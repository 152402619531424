@import 'styles/includes.scss';

.Article {
    font-family: $fontBrand;
    background-color: $colorGray10;
    color: $colorBlack800;
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;

    @include media(ml) {
        align-items: center;
        flex-direction: row;

        &--ImageRight {
            justify-content: space-between;
            flex-direction: row-reverse;
        }
    }

    &__Image {
        width: 100%;
        margin-bottom: $spacingL;

        @include media(ml) {
            margin-bottom: 0;
            width: calc(50% + 2.6rem);
        }
    }

    &__TextContainer {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @include media(ml) {
            width: calc(50% - 2.6rem);
            margin-left: 0rem;
        }

        @include media(ml) {
            margin-left: 5.6rem;
            min-width: 35rem;

            &--ImageRight {
                margin-left: 0;
                margin-right: 5.6rem;
            }
        }

        @include media(xl) {
            max-width: 72rem;
        }
    }

    &__Headline {
        display: inline-block;
        font-weight: bold;
        font-size: 2.6rem;
        line-height: 1.32;
        margin-bottom: $spacingXl;
        letter-spacing: 0.4px;

        @include media(m) {
            line-height: 1.31;
            font-size: 3.5rem;
            margin-bottom: $spacingL;
        }
    }

    &__Preamble {
        margin-bottom: $spacingXl;

        @include media(m) {
            margin-bottom: $spacingL;
        }
    }

    &__Quote {
        font-weight: normal;
        font-size: 1.6rem;
        line-height: 1.65;
        color: $colorBlack750;

        @include media(m) {
            font-size: 1.6rem;
        }
    }

    &__BylineContainer {
        margin-bottom: $spacingXl;

        @include media(m) {
            margin-bottom: $spacingXl;
        }
    }

    .Picture--Large {
        width: 100%;

        @include media(ml) {
            min-width: 40rem;
        }
    }
}
